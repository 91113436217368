/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Styled, jsx } from 'theme-ui'
import { Link, graphql } from 'gatsby'
//
import Layout from '../components/layout'
import Search from '../components/search'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { ImageFixed } from '../utils/image'
import Filters from '../components/filters'

const defaultTags = {
  typography: false,
  editorial: false,
  packaging: false,
  branding: false,
  illustration: false,
  wallpaper: false
}
const totalTags = Object.keys(defaultTags).length

const IllustrationItem = ({ id, title, to, active, date, image }) => {
  return active ? (
    <Link
      className="active"
      key={id}
      to={`${to}/`}
      onClick={e => e.preventDefault()}
      sx={{
        display: 'flex',
        color: 'muted',
        textDecoration: 'none',
        cursor: 'default',
        variant: 'styles.navlink',
        p: 2,
        lineHeight: 1.2,
        mb: 3,
        ':before': { content: '" "' }
      }}
    >
      <div>{<ImageFixed image={image} alt={image?.title || title} />}</div>
      <div>
        <span>{title}</span>
        <br />
        <span sx={{ fontSize: '0.7rem' }}>{date}</span>
      </div>
    </Link>
  ) : (
    <Link
      key={id}
      to={`${to}/`}
      sx={{
        display: 'flex',
        color: 'text',
        textDecoration: 'none',
        variant: 'styles.navlink',
        p: 1,
        lineHeight: 1.2,
        mb: 3,
        img: {
          filter: 'none'
        },
        ':hover': {
          img: {
            filter: 'grayscale(100%)'
          },
          '.desc': {
            fontWeight: 500
          }
        }
      }}
    >
      <div>{<ImageFixed image={image} alt={image?.title || title} />}</div>

      <div sx={{ paddingLeft: '5px' }} className="desc">
        <span>{title}</span>
        <br />
        <span sx={{ fontSize: '0.7rem' }}>{date}</span>
      </div>
    </Link>
  )
}

const Works = ({ data, location }) => {
  // const location = useLocation()

  const { allContentfulIllustration } = data

  const [searchActive, setSearchActive] = useState(false)
  const [tags, setTags] = useState(defaultTags)
  const [illustrations, setIllustrations] = useState([])
  const [filteredIllustrations, setFilteredIllustrations] = useState([])
  const [loaded, setLoaded] = useState(false)

  const handleTagClick = tag => {
    const newTags = { ...tags }
    newTags[tag] = !tags[tag]
    const enabledTags = Object.keys(newTags).filter(key => newTags[key])
    if (enabledTags.length > 0 && enabledTags.length < totalTags) {
      window.history.pushState('', document.title, `${location.pathname}?tags=${enabledTags.join(',')}`)
    } else {
      window.history.pushState('', document.title, `${location.pathname}`)
    }

    setTags(newTags)
  }

  useEffect(() => {
    const fetchedIllustrations =
      allContentfulIllustration?.edges
        ?.map(edge => edge.node)
        .map(node => {
          const nodeTag = node?.tags
            ?.map(tag => tag.toLowerCase().trim())
            ?.find(tag => {
              return Object.keys(defaultTags).includes(tag)
            })

          return {
            id: node.id,
            title: node.title,
            to: `/works/${node.slug}`,
            active: location.pathname.includes(node.slug),
            date: node?.date || '',
            tag: nodeTag || null,
            image: node?.images?.[0]
          }
        }) || []

    setIllustrations(fetchedIllustrations)
    setFilteredIllustrations(fetchedIllustrations)
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) {
      const enabledTags = Object.keys(defaultTags).filter(key => tags[key])
      if (enabledTags.length > 0 && enabledTags.length < totalTags) {
        const newIllustrations = illustrations.filter(illustration => enabledTags.some(tag => tag === illustration.tag))
        setFilteredIllustrations(newIllustrations)
      } else {
        setFilteredIllustrations(illustrations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  useEffect(() => {
    const enabledTags = location?.search
      ?.replace('?tags=', '')
      ?.split(',')
      ?.filter(tag => tag?.length)
      ?.filter(tag => Object.keys(defaultTags).includes(tag))

    const newTags = { ...defaultTags }

    if (enabledTags?.length > 0 && enabledTags?.length < totalTags) {
      Object.keys(tags).forEach(tag => {
        newTags[tag] = enabledTags.includes(tag)
      })
    }

    setTags(newTags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Layout location={location}>
      <Seo title="Works" description="My Works, view my full list of artworks" cardType="summary" />
      <Styled.div sx={{ mb: 4, pb: 4 }}>
        <div
          sx={
            searchActive
              ? {
                  filter: 'blur(5px)',
                  opacity: '0.95'
                }
              : {}
          }
        >
          <Styled.h2 sx={{ mb: 1, fontSize: '1.3rem' }}>Works</Styled.h2>

          <div sx={{ mt: 4, mb: 4 }}>
            <Filters
              tags={tags}
              handleClick={handleTagClick}
              handleTotal={tag => filteredIllustrations.filter(item => item.tag === tag).length}
            />
          </div>

          <div
            sx={{
              display: 'grid',
              gridTemplateRows: 'repeat(4, 64px)',
              gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(5, 1fr)'],
              gridAutoFlow: 'row',
              fontSize: '1rem',
              paddingRight: '2rem',
              minHeight: '70vh'
            }}
          >
            {filteredIllustrations?.map(item => (
              <IllustrationItem key={item.id} {...item} />
            ))}
          </div>
        </div>

        <Search
          handleActive={active => setSearchActive(active)}
          handleSearch={regex =>
            filteredIllustrations?.filter(item => item?.title?.match(regex) || item?.description?.match(regex)) || []
          }
        />
      </Styled.div>
      <Footer />
    </Layout>
  )
}

export const pageWorksQuery = graphql`
  query {
    allContentfulIllustration(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          slug
          tags
          date(formatString: "MMM YYYY")
          images {
            title
            fixed(height: 36, width: 64) {
              ...FragmentImageFixed
            }
          }
        }
      }
    }
  }
`

export default Works
